<template>
  <div class="shopMember">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/order1' }">订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>全部订单</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="shop-screen">
      <div class="btn-list">
        <el-button
          type="primary"
          size="small"
          style="background: #4781d9; border: 0"
          class="btn-item"
          @click="chainGoodExport"
        >
          <span>导出</span>
          <i class="iconfont icon-daochu" style="font-size:12px"></i>
        </el-button>
      </div>
      <el-form
        ref="form"
        :model="orderForm"
        label-width="80px"
        size="mini"
        class="shopScreenForm"
      >
        <el-form-item label="订单号" label-width="60px">
          <el-input
            v-model="orderForm.orderNum"
            style="width: 260px"
          ></el-input>
        </el-form-item>
        <el-form-item label="取袋时间" style="margin-left: 50px">
          <el-date-picker
            v-model="orderForm.timer"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            style="background: #cd0550; border: none"
            @click="search"
            >搜索</el-button
          >
          <el-button @click="emptySearch">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="data-table">
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        type="index"
        :header-cell-style="{ background: '#CD0550', color: '#fff' }"
      >
        <el-table-column prop="id" label="ID"/>
        <el-table-column prop="order_no" label="订单号"/>
         <el-table-column prop="good_name" label="商品名称" v-if="is_supermarket!=1"/>
        <el-table-column prop="order_amount" label="订单金额" v-if="is_supermarket!=1"/>
        <el-table-column prop="good_name" label="盒子名称" v-if="is_supermarket==1"/>
        <el-table-column prop="good_quantity" label="盒子数量" v-if="is_supermarket==1"/>
        <el-table-column prop="total_good_price" label="商品总价" v-if="is_supermarket==1"/>
        <el-table-column prop="total_good_discount_price" label="约定折扣价" v-if="is_supermarket==1"/>
        <el-table-column prop="total_settle_price" label="商品结算金额" v-if="is_supermarket==1"/>
        <el-table-column prop="total_platform_price" label="平台服务费" v-if="is_supermarket==1"/>
        <el-table-column prop="packing_fee" label="打包费" v-if="is_supermarket==1"/>
        <el-table-column label="订单状态">
          <template slot-scope="scope">
            <span
              :style="{
                color:
                  scope.row.status == 1
                    ? '#CD0550'
                    : scope.row.status == 2 || scope.row.status == 6
                    ? '#5FAD99'
                    : '#333333',
              }"
              >{{ scope.row.status_str }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="user_phone" label="用户手机号"/>
        <el-table-column label="取袋时间">
          <template slot-scope="scope">
            {{
              scope.row.pickup_date +
              " " +
              scope.row.pickup_time_start +
              "-" +
              scope.row.pickup_time_end
            }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="下单时间"/>
        <el-table-column label="操作">
            <template slot-scope="scope">
              <div style="position: relative; display: inline">
                <i
                  style="color: #3ca6f0"
                  @click.stop="operation(scope.row)"
                  class="iconfont icon-bianji"
                >
                </i>
                <div v-if="scope.row.num == num" class="operation">
                  <p @click.stop="seeGoods(scope.row)" :class="active == 1 ? 'active' : ''">
                    查看
                  </p>
                  <p @click.stop="print(scope.row)" v-if="scope.row.status == 1" >
                    打印
                  </p>
                </div>
              </div>
            </template>
          </el-table-column>
      </el-table>
      <div class="page-num">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="prev,pager,next, total,sizes,jumper"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
    <!-- 打印样板 -->
    <div id="orderAll" class="testbox" v-if="showHtml">
        <div style="font-size:32px;font-width:bolder;text-align: center;">#{{orderData.pickup_code}}</div>
        <div style="font-size:18px;font-width:bolder;text-align: center;">{{orderData.shop_name}}</div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">商品名称:</div><div style="font-size:11px">{{orderData.good_name}}</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">商品数量:</div><div style="font-size:11px">{{orderData.good_quantity}}</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">订单号:</div><div style="font-size:11px">{{orderData.order_no}}</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">下单时间:</div><div style="font-size:11px">{{orderData.created_at}}</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">取货时间:</div><div style="font-size:11px">{{orderData.pickup_date}} {{orderData.pickup_time_start}}~{{orderData.pickup_time_end}}</div></div>
         <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">门市价格:</div><div style="font-size:11px">${{orderData.price_type==1?orderData.original_price:orderData.original_price_range_start+"-"+orderData.original_price_range_end}}</div></div>
        <div style="display:flex;justify-content: space-between;font-size:12px; line-height:20px "><div style="width:60px">订单金额:</div><div style="font-size:11px">${{orderData.order_amount}}</div></div>
        <p style="color:#eee">---------------------------------</p>
    </div>
  </div>
</template>

<script>
import { getOrderList_api,VgetOrderList_api } from "@/api/store/order.js"
import axios from "axios"
import { httpUrl } from "@/utils/http.js"
import local from "@/utils/local.js"
import {getLodop} from "@/utils/LodopFuncs.js"

//定义一个变量
var orderLodop;
//程序加载有个加载过程，进页面立即初始化的话，会提示未加载完成
function Init(){
    //初始化变量 str1 str2默认不填 ，是注册正版时的验证账号密码
    orderLodop= getLodop();
    // orderLodop.SET_LICENSES("","EE0887D00FCC7D29375A695F728489A6","C94CEE276DB2187AE6B65D56B3FC2848","");
}
 //执行lodop_print()即可直接打印
function lodop_print(){
  //需要打印的内容
  var html = document.getElementById("orderAll").innerHTML;
  orderLodop.PRINT_INIT();
  //设置打印页面大小，这里3表示纵向打印且纸高“按内容的高度”；48表示纸宽48mm；20表示页底空白2.0mm
  orderLodop.SET_PRINT_PAGESIZE(3,580,100,"11");
  //设置打印页面内容  10为上边距 0为左边距 100%为宽度 ""为高度，发现不填也没事，html是打印内容                 
  orderLodop.ADD_PRINT_HTM(0,0,'100%',"100",html);
  //打印操作
  orderLodop.PRINT();
  console.log("打印了")
}

export default {
  data() {
    return {
      orderForm: {
        shopName: "",
        orderNum: "",
        timer: "",
      },
      currentPage: 1,
      pageSizes: [10, 20, 50],
      pageSize: 10,
      total: 0,
      tableData: [],
      num: 0,
      active: 1,
      filename: "全部订单",
      active: 1,
      num: 0,
      showHtml:false,
      orderData:{},
      is_supermarket:''
    }
  },
  methods: {
    //打印
    print(data){
      this.showHtml=true
      this.orderData=data
      this.active=1
      this.num=0
      setTimeout(()=>{
        lodop_print()
      },1000)
    },
    //查看
    seeGoods(row){
      local.set("orderNum",JSON.stringify(row))
      this.$router.push("/order/outGoods1")
    },
    //显示操作页面
    operation(row) {
      if (this.num == row.num) {
        this.num = 0
      } else {
        this.num = row.num
      }
    },
    //重置
    emptySearch() {
      this.currentPage = 1
      this.pageSize = 10
      this.orderForm={
        shopName: "",
        orderNum: "",
        timer: "",
      },
      this.getOrderList()
    },
    //搜索
    search() {
      console.log(this.orderForm.timer)
      this.currentPage = 1
      this.getOrderList()
    },
    //改变条数
    handleSizeChange(e) {
      this.pageSize = e
      this.getOrderList()
    },
    //切换页
    handleCurrentChange(e) {
      this.currentPage = e
      this.getOrderList()
    },
    //获取订单列表
    async getOrderList() {
      const IsSupermarket1=this.is_supermarket==1?VgetOrderList_api:getOrderList_api
      let res = await IsSupermarket1({
        shop_name: this.orderForm.shopName,
        order_no: this.orderForm.orderNum,
        page: this.currentPage,
        limit: this.pageSize,
        pickup_date_start: this.orderForm.timer?this.orderForm.timer[0]:'',
        pickup_date_end: this.orderForm.timer?this.orderForm.timer[1]:'',
      })
      this.tableData = res.data.data.list
      this.total = res.data.data.total_count
       this.tableData.forEach((item, index) => {
        item.num = index + 1
      })
    },
    //导出
    chainGoodExport() {
      const loading = this.$loading({
          lock: true,
          text: '导出中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
      const IsV1=`${httpUrl}api/v1/store/order/export?type=1&shop_name=${this.orderForm.shopName}&order_no=${this.orderForm.orderNum}&pickup_date_start=${this.orderForm.timer[0]?this.orderForm.timer[0]:''}&pickup_date_end=${this.orderForm.timer[1]?this.orderForm.timer[1]:''}`
      const IsV2=`${httpUrl}api/v2/store/order/export?type=1&shop_name=${this.orderForm.shopName}&order_no=${this.orderForm.orderNum}&pickup_date_start=${this.orderForm.timer[0]?this.orderForm.timer[0]:''}&pickup_date_end=${this.orderForm.timer[1]?this.orderForm.timer[1]:''}`
      const IsV=this.is_supermarket==1?IsV2:IsV1
      axios
        .get(
         IsV,
          {
            responseType: "blob", //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
          }
        )
        .then((res) => {
          if (!res) return
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          }) // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename)
          } else {
            const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
            const a = document.createElement("a") //创建a标签
            a.style.display = "none"
            a.href = href // 指定下载链接
            a.download = this.filename //指定下载文件名
            a.click() //触发下载
            URL.revokeObjectURL(a.href) //释放URL对象
             loading.close()
          }
          // 这里也可以不创建a链接，直接window.open(href)也能下载
        })
        .catch((err) => {
          this.$message.error('导出失败,请重试!')
          loading.close()
        })
    },
  },
  created() {
    this.is_supermarket=local.get("is_supermarket")
    this.getOrderList()
    setTimeout(()=>{
      Init()
    },500)
  },
}
</script>

<style lang="less" scoped>
.testbox{
  margin: 0;
  padding: 0;
  position: fixed;
  right: -99999999px;
}
.shopMember {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
}
.shop-screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 20px;
}
.shopScreenForm {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  margin-top: 20px;
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #cd0550 !important;
}
/deep/.el-pager li {
  border: 1px solid #bfbfbf;
  background: #fff !important;
  box-sizing: border-box !important;
}
/deep/.el-pagination.is-background .btn-next {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-pagination.is-background .btn-prev {
  border: 1px solid #bfbfbf;
  background: #fff !important;
}
/deep/.el-table {
  overflow: visible;
}
/deep/td {
  text-align: center !important;
}
/deep/.cell {
  text-align: center !important;
  overflow: visible;
}
/deep/.el-table__body-wrapper {
  overflow: visible;
}
/deep/.is-active {
  color: #cd0550;
}
/deep/.el-tabs__active-bar {
  background: #cd0550;
}
.data-table {
  margin-top: 20px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
}
.page-num {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
.operation {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: -90px;
  z-index: 99;
  background: #fff;
  p {
    width: 85px;
    height: 40px;
    // background: rgba(246, 219, 229, 0.2);
    border-radius: 0px;
    text-align: center;
    line-height: 40px;
    border: 1px #eee solid;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
}
.active {
  background: #cd0550 !important;
  color: #fff !important;
}
.btn-list {
  display: flex;
  align-items: center;
}
</style>